function AnalogClock(canvas) {
    this.canvas = canvas;

    this.start = function () {
        var fps = 3;

        this.ctx = this.canvas.getContext("2d");
        this.radius = (0.9 * this.canvas.height) / 2;
        this.ctx.translate(this.canvas.height / 2, this.canvas.height / 2);

        this.drawClock();

        var _this = this;
        setInterval(function () {
            _this.drawClock();
        }, 1000.0 / fps);
    };

    this.drawClock = function () {
        this.drawFace();
        this.drawNumbers();
        this.drawHands();
    };

    this.drawFace = function () {
        // main face fill
        this.ctx.beginPath();
        this.ctx.arc(0, 0, this.radius, 0, 2 * Math.PI);
        this.ctx.fillStyle = "white";
        this.ctx.fill();

        // outside circle
        var grad = this.ctx.createRadialGradient(
            0,
            0,
            this.radius * 0.97,
            0,
            0,
            this.radius * 1.03,
        );
        grad.addColorStop(0, "#fff");
        grad.addColorStop(0.5, "#000");
        grad.addColorStop(1, "#fff");
        this.ctx.strokeStyle = grad;

        this.ctx.lineWidth = this.radius * 0.03;
        this.ctx.stroke();
        this.ctx.strokeStyle = "#333";

        // center circle
        this.ctx.beginPath();
        this.ctx.arc(0, 0, this.radius * 0.08, 0, 2 * Math.PI);
        this.ctx.fillStyle = "#333";
        this.ctx.fill();
    };

    this.drawNumbers = function () {
        this.ctx.beginPath();
        this.ctx.fillStyle = "#333";
        this.ctx.font = this.radius * 0.25 + "px arial";
        this.ctx.textBaseline = "middle";
        this.ctx.textAlign = "center";
        for (var num = 1; num < 13; num++) {
            var ang = (num * Math.PI) / 6;
            this.ctx.rotate(ang);
            this.ctx.translate(0, -this.radius * 0.8);
            this.ctx.rotate(-ang);
            this.ctx.fillText(num.toString(), 0, 0);
            this.ctx.rotate(ang);
            this.ctx.translate(0, this.radius * 0.8);
            this.ctx.rotate(-ang);
        }
    };

    this.drawHands = function () {
        var time = getTzOffsetNow(this.canvas.dataset.tzOffset);
        var hour = time.getHours();
        var minute = time.getMinutes();

        this.drawOneHand((hour % 12) + minute / 60.0, this.radius * 0.5, this.radius * 0.04);
        this.drawOneHand(minute / 5, this.radius * 0.8, this.radius * 0.03);
    };

    this.drawOneHand = function (pos, length, width) {
        this.ctx.beginPath();
        this.ctx.lineWidth = width;
        this.ctx.lineCap = "round";
        this.ctx.moveTo(0, 0);
        this.ctx.rotate((pos * 2 * Math.PI) / 12);
        this.ctx.lineTo(0, -length);
        this.ctx.stroke();
        this.ctx.rotate((-pos * 2 * Math.PI) / 12);
    };

    this.start();
    return this;
}

function StringClock(element) {
    this.element = element;

    this.start = function () {
        var fps = 3;
        var _this = this;

        this.updateTime();
        setInterval(function () {
            _this.updateTime();
        }, 1000.0 / fps);
    };

    this.updateTime = function () {
        var time = getTzOffsetNow(this.element.dataset.tzOffset);
        var symbols = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var month = time.getMonth() + 1;
        var day = time.getDate();
        var week = time.getDay();
        var hour = ("0" + time.getHours()).slice(-2);
        var minute = ("0" + time.getMinutes()).slice(-2);
        var result = month + "/" + day + " (" + symbols[week] + ") " + hour + ":" + minute;

        this.element.innerHTML = result;
    };

    this.start();
    return this;
}

function getTzOffsetNow(tz_offset) {
    var now = new Date();
    var target = new Date();
    var local_offset = now.getTimezoneOffset() / 60.0;

    if (tz_offset !== "" && tz_offset !== null) {
        target.setTime(now.getTime() + (parseInt(tz_offset) + local_offset) * 60 * 60 * 1000);
    }
    return target;
}

export default function runClocks() {
    var i;
    var clocks;

    clocks = document.getElementsByClassName("analog-clock");
    for (i = 0; i < clocks.length; i++) {
        new AnalogClock(clocks[i]);
    }

    clocks = document.getElementsByClassName("string-clock");
    for (i = 0; i < clocks.length; i++) {
        new StringClock(clocks[i]);
    }
}
